<template>
  <div class="badges-wrapper">
    <badge-item v-for="badge in badges" :key="badge" :badge-id="badge" :type="type" />
    <ion-badge v-if="moreCounter" mode="md" class="counter">{{ '+' + moreCounter }}</ion-badge>
  </div>
</template>

<script lang="ts" setup>
import { IonBadge } from '@ionic/vue';
import type { PropType } from 'vue';

import { BadgeItem } from '@/components';
import { BadgeDisplayType } from '@/enums';

defineProps({
  badges: {
    type: Array as PropType<number[]>,
    required: true,
  },
  type: {
    type: Number as PropType<BadgeDisplayType>,
    required: true,
  },
  moreCounter: {
    type: [Number, null] as PropType<number | null>,
    default: null,
  },
});
</script>

<style scoped lang="scss">
.badges-wrapper {
  display: flex;
  overflow: hidden;
  .counter {
    color: var(--ion-color-medium);
    --background: var(--ion-color-light-custom);
    border-radius: app-radius(lg);
    min-width: 1.2rem;
    font-weight: 500;
    margin-left: calc(app-padding(xl) * -1);
    padding-left: app-padding(xl);
  }
}
</style>
