import { SplashScreen } from '@capacitor/splash-screen';
import { alertController } from '@ionic/vue';
import * as Sentry from '@sentry/capacitor';
import { PagesBackgroundEnum, PostsModeEnum, RequirementsEnum } from '@/enums';
import {
  isNativeMobile,
  useWebSockets,
  useTheme,
  HomePageHelper,
  openNetworkChooseModal,
  updateApp,
  changeFavicon,
  ColorGenerator,
  useRequirements,
  useAiAssistant,
  useImages,
  useNotifications,
  isPushInitialized,
} from '@/helpers';
import { useI18n, loadLocaleMessages } from '@/i18n';
import router, { ROUTES_NAME } from '@/router';
import type { AppState } from '@/store';
import {
  useAppStore,
  useMessengerStore,
  useNetworkStore,
  useGroupsStore,
  useUserStore,
  useNotificationsStore,
  usePostStore,
  useMenuStore,
  resetAllStores,
  useAiAssistantStore,
  useBadgesStore,
} from '@/store';
import type { AuthUser, NetworkModel, UserCurrentModel } from '@/types';
import { markEnd, markStart } from '@/helpers/performance';

type IUseUserFlow = {
  /**
   * The current user of the app.
   *
   * @see src/plugins/sentry.ts
   */
  localUser: UserCurrentModel | null;
  /**
   * General setup function for the app
   *
   * @summary Setup network, user, profile settings, locales, check required conditions, to app + init WebSockets and/or Push Notifications.
   * Accepts newWebSocket and newPush as optional parameters for _checkRequiredConditions => _toApp.

   * @param newWebSocket boolean
   * @description If newWebSocket is false, then it will NOT re-initialize WebSockets connection.
   * @default true
   * @see src/helpers/useWebSockets.ts

   * @param newPush boolean
   * @description If newPush is false, then it will NOT re-initialize Push Notifications. It will hoverer if isPushInitialized is false.
   * @default true
   * @see src/helpers/useNotificationsHelper.ts
   */
  setupApp: (newWebSocket?: boolean, newPush?: boolean) => Promise<void>;
  setNetwork: (network: NetworkModel, data: AuthUser | null) => Promise<void>;

  /**
   * Changes the network for the user.
   * Displays a modal to select a network and sets it.
   */
  changeNetwork: (userNetworks: NetworkModel[] | undefined, data: AuthUser | null) => Promise<void>;

  /**
   * Logs out the user from the application.
   *  - Stops WebSockets
   *  - resets badge count
   *  - deletes ai assistant
   *  - cancels push notifications
   *  - clears custom menu timer
   *  - resets all stores
   *  - changes favicon
   *  - clears theme
   *  - clears images cache
   */
  logOut: () => Promise<void>;

  /**
   * Clears the custom menu refresh timer.
   */
  clearCustomMenuTimer: () => void;

  /**
   * Sets a timer to refresh the custom menu at regular intervals.
   */
  setCustomMenuTimer: () => void;
};

export const useUserFlow = (): IUseUserFlow => {
  /**
   * --------------------------------------------------------------------------------
   * Variables
   * --------------------------------------------------------------------------------
   */
  let localUser: UserCurrentModel | null = null;

  /**
   * --------------------------------------------------------------------------------
   * Stores
   * --------------------------------------------------------------------------------
   */
  const appStore = useAppStore();
  const networkStore = useNetworkStore();
  const userStore = useUserStore();
  const menuStore = useMenuStore();
  const postStore = usePostStore();
  const messengerStore = useMessengerStore();
  const aiAssistantStore = useAiAssistantStore();

  /**
   * --------------------------------------------------------------------------------
   * Helpers
   * --------------------------------------------------------------------------------
   */
  const themeHelper = useTheme();

  /**
   * --------------------------------------------------------------------------------
   * Private methods
   * --------------------------------------------------------------------------------
   */
  const _setupNetwork = async (): Promise<boolean> => {
    if (networkStore.network.id.length === 0) {
      await networkStore.networkForUser();
    }

    if (networkStore.data.length === 0) {
      await networkStore.networksByUser();
    }

    const settings = await networkStore.getSettings();

    if (!settings) {
      return false;
    }

    const color = themeHelper.getAppColor(settings?.headBackgroundColor);
    await themeHelper.setTheme(color);
    changeFavicon();
    return true;
  };

  const _setupUser = async (): Promise<UserCurrentModel | null> => {
    localUser = await userStore.currentUser();

    if (!localUser) {
      return null;
    }

    appStore.$patch({
      userId: localUser.id,
    });

    return localUser;
  };

  const _handleSetupAppError = async (): Promise<void> => {
    if (isNativeMobile) {
      await SplashScreen.hide();
    }

    appStore.$patch({
      isLoading: false,
    });

    await router?.replace(ROUTES_NAME.LOGIN);
    const networkErrors = networkStore.getErrors('default');
    const userErrors = userStore.getErrors('default');
    const errors = userErrors.length && !networkErrors.length ? userErrors : networkErrors;

    Sentry?.setUser(null);

    const alert = await alertController.create({
      message: errors.join(' '),
    });
    await alert.present();
  };

  const _setupProfileSettings = (localUser: UserCurrentModel): void => {
    appStore.$patch((state: AppState) => {
      state.isLoading = true;

      state.theme = Object.hasOwn(localUser, 'themeStyle') ? localUser.themeStyle : state.theme;

      state.pagesBackground = Object.hasOwn(localUser, 'pagesBackground')
        ? localUser.pagesBackground
          ? PagesBackgroundEnum.CustomImage
          : PagesBackgroundEnum.Default
        : state.pagesBackground;

      state.locale = Object.hasOwn(localUser, 'language') ? localUser.language : state.locale;

      state.sendKey = Object.hasOwn(localUser, 'sendMode') ? localUser.sendMode : state.sendKey;

      state.localNotifications = Object.hasOwn(localUser, 'sound') ? localUser.sound : state.localNotifications;

      state.canPostOnBehalfMe = Object.hasOwn(localUser, 'canPostOnBehalfMe')
        ? localUser.canPostOnBehalfMe
        : state.canPostOnBehalfMe;
    });

    appStore.setTheme(appStore.theme);
  };

  const _setupLocales = async (): Promise<void> => {
    const companyResources = await appStore.getCompanyResources();
    const currentNetworkId = useNetworkStore().getNetwork.id;
    await loadLocaleMessages(useI18n(), appStore.locale, companyResources, currentNetworkId);
  };

  const _toApp = async (newWebSocket = true, newPush = true): Promise<void> => {
    const route = router.currentRoute.value;
    if (!localUser) {
      if (isNativeMobile) {
        await SplashScreen.hide();
      }

      await router?.push(ROUTES_NAME.LOGIN);
      return;
    }

    if (newWebSocket) {
      await useWebSockets().initWebSockets(appStore.getWebSocketModel);
    }

    if (newPush || !isPushInitialized()) {
      await useNotifications().initPushNotifications();
    }

    userStore.$reset();
    userStore.$patch({
      current: localUser,
    });

    appStore.$patch({
      feedType: localUser.defaultFeedType,
    });

    /* NOTE:
      If initialization was caused by mobile local/push notification handling
      we need to ignore following routing logic since final route is now dictated by push notification type/id
    */
    if (!appStore.fromMobilePush) {
      /* NOTE: If the user was targeting specific page, we need to redirect back to it */
      if (appStore.redirectUrl) {
        await router.push(appStore.redirectUrl);
        appStore.setRedirectUrl(null);
        return;
      }

      /* NOTE: If the user was not targeting specific page - default behavior */
      await HomePageHelper.setHomePage();
      if (route?.name === ROUTES_NAME.LOGIN || route?.name === ROUTES_NAME.HOME) {
        await router?.replace(appStore.homePage);
      } else {
        if (HomePageHelper.getHomePage()?.name === ROUTES_NAME.FEED) {
          await router?.replace({ name: ROUTES_NAME.FEED });
          postStore.$patch((state) => {
            state.postsMode = PostsModeEnum.Feed;
            state.postsGroupId = null;
            state.postsUserId = null;
          });
        } else {
          await router?.replace(appStore.homePage);
        }
      }
    }

    if (isNativeMobile) {
      await SplashScreen.hide();
      await updateApp();
    }

    await messengerStore.getChains();
    for (let i = 0; i < 10 && messengerStore.hasLoadMoreUrl; i++) {
      await messengerStore.loadMore(messengerStore.hasLoadMoreUrl);
    }
  };

  const _checkRequiredConditions = async (newWebSocket?: boolean, newPush?: boolean): Promise<void> => {
    if (isNativeMobile) {
      await SplashScreen.hide();
    }

    const result = await useRequirements().check(RequirementsEnum.All);
    if (result) {
      appStore.$patch({
        isLoading: false,
      });
      await _toApp(newWebSocket, newPush);
    }
  };

  /**
   * --------------------------------------------------------------------------------
   * Public methods
   * --------------------------------------------------------------------------------
   */
  const setupApp = async (newWebSocket?: boolean, newPush?: boolean): Promise<void> => {
    markStart('setupApp');
    clearCustomMenuTimer();

    resetAllStores(false);

    ColorGenerator.generateRandomShades();

    try {
      const _result = await _setupNetwork();
      if (!_result) {
        throw new Error('Network error');
      }

      localUser = await _setupUser();
      if (!localUser) {
        throw new Error('Current user error');
      }
    } catch (e) {
      console.error('[ERROR] Failed to setupApp', e);
      await _handleSetupAppError();
      return;
    }

    if (Sentry) {
      Sentry.setUser({
        email: localUser?.email,
        id: localUser?.id.toString(),
        username: localUser?.fullName,
      });
    }

    _setupProfileSettings(localUser);

    // await _setupLocales();
    // await _checkRequiredConditions(newWebSocket, newPush);
    // await useNotificationsStore().notifications();
    // await useGroupsStore().canPostGroups();
    // await useMenuStore().getMenu();
    // await useGroupsStore().myGroups();

    /**
     * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/allSettled#description
     */
    try {
      await Promise.allSettled([
        _setupLocales(),
        _checkRequiredConditions(newWebSocket, newPush),
        useNotificationsStore().notifications(),
        useGroupsStore().canPostGroups(),
        useMenuStore().getMenu(),
        useGroupsStore().myGroups(),
        !useBadgesStore().data.length && useBadgesStore().badgesAll(),
      ]);
    } catch (e: any) {
      console.error('[ERROR] Error during setupApp', e);
    }

    setCustomMenuTimer();
    markEnd('setupApp');
  };

  const setNetwork = async (network: NetworkModel, userData: AuthUser | null): Promise<void> => {
    networkStore.$patch({
      settingNetwork: true,
    });
    appStore.$patch({
      isLoading: true,
    });

    if (network.id !== '') {
      appStore.setNetwork(`https://${network.hostWithSubHost}`, network.id, network.webSocket, true);

      let isAuth = false;
      /* NOTE: If user data is provided AND user is NOT authenticated => try to get a new code */
      if (userData && !appStore.isAuth()) {
        isAuth = await appStore.homeCode(userData);
      }

      /* NOTE: If user data is NOT provided AND user is authenticated => we are already authenticated */
      if (!userData && appStore.isAuth()) {
        isAuth = true;
      }

      //?: Should we check for appStore.isAuth() AND isAuth here?
      await appStore.token(true);
      if (isAuth && appStore.isAuth()) {
        networkStore.$patch((state) => {
          state.network = network;
          state.settingNetwork = false;
        });

        await setupApp();
      }
    }

    networkStore.$patch({
      settingNetwork: false,
    });
    appStore.$patch({
      isLoading: false,
    });
  };

  const changeNetwork = async (userNetworks: NetworkModel[] | undefined, data: AuthUser | null): Promise<void> => {
    if (userNetworks !== undefined) {
      if (userNetworks.length === 1) {
        await setNetwork(userNetworks[0], data);
      } else {
        const result = await openNetworkChooseModal(false);
        if (result !== undefined) {
          await setNetwork(result, data);
        }
      }
    }
  };

  const logOut = async (): Promise<void> => {
    try {
      console.log('[INFO] Logging out user from the app'); //! DEBUG

      console.log('[INFO] Stopping WebSockets'); //! DEBUG
      await useWebSockets().stopWebSockets();

      console.log('[INFO] Resetting badge count'); //! DEBUG
      await useNotifications().resetBadgeCount();

      const currentAssistant = aiAssistantStore.assistant;
      if (currentAssistant) {
        console.log('[INFO] Deleting current assistant'); //! DEBUG
        await useAiAssistant().deleteAssistant(currentAssistant);
      }

      if (isNativeMobile && appStore.isAuth()) {
        console.log('[INFO] Cancelling push notifications'); //! DEBUG
        await useNotifications().cancelPushNotifications();
      }

      console.log('[INFO] Clearing custom menu timer'); //! DEBUG
      clearCustomMenuTimer();

      console.log('[INFO] Resetting all stores'); //! DEBUG
      resetAllStores(true);

      console.log('[INFO] Changing favicon'); //! DEBUG
      changeFavicon();

      console.log('[INFO] Clearing theme'); //! DEBUG
      await themeHelper.clearTheme();

      console.log('[INFO] Clearing images'); //! DEBUG
      useImages().clearImages();
    } catch (e) {
      console.error(e);
    }
  };

  //* Normal Functions to be accessible from everywhere
  function setCustomMenuTimer() {
    const customMenuTimerId = setInterval(
      async () => {
        await menuStore.getMenu();
      },
      15 * 60 * 1000
    );
    menuStore.setTimer(customMenuTimerId);
  }

  function clearCustomMenuTimer() {
    const customMenuTimerId = menuStore.timer;
    if (customMenuTimerId) {
      clearInterval(customMenuTimerId);
    }
    menuStore.setTimer(null);
  }

  return {
    localUser,
    setupApp,
    setNetwork,
    changeNetwork,
    logOut,
    clearCustomMenuTimer,
    setCustomMenuTimer,
  };
};
